.page-home-qr {
  width: 100%;
  padding: 1.5rem;
  border-radius: 8px;
}

div.page-home-name {
  margin-top: 0.2rem;
}

.page-home-subtitle {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

div.page-home-content {
  margin-top: 2rem;
}

.page-home-content-header {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}