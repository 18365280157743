.page-front{
  height: 100vh;
}

.page-container {
  margin-left: 6vw;
  margin-right: 6vw;
  height: 100vh;
}

div.page-container {
  width: inherit;
}

.page-front-font,
.MuiTypography-root.page-front-font {
  font-family: "Poppins";
}

.page-front-title,
.page-front-title p,
.MuiTypography-root.page-front-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  font-family: "Poppins";
}

.MuiTypography-root.page-front-title.page-front-label-sm {
  margin-top: 3vh;
}

.page-front-title p.page-front-label-lg {
  margin-bottom: 0;
}

.page-front-subtitle,
.page-front-subtitle p,
.MuiTypography-root.page-front-subtitle {
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  font-family: "Roboto";
}

.page-front-subtitle p.page-front-label-lg {
  margin-bottom: 0;
}

.page-front-logo {
  min-width: 100%;
}

.page-front-logo,
.page-front-logo img {
  width: 100%;
  margin-top: 2vh;
  max-width: 214px;
}

div.page-front-btns {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding-bottom: 2vh;
  /*margin-top: 4vh;*/
}

.page-front-btns {
  min-width: 100%;
  margin-top: 1vh;
}

.page-front-btns ul {
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.page-front-content {
  margin-bottom: 2vh;
  margin-top: 1vh;
  p {
    margin-bottom: 3vh;
  }
  button {
    margin-top: 2vh;
  }
}
.page-front-content-data{
  margin-right: 2rem;
}

.page-front-logoland {
  width: 95%;
  max-width: 400px;
  min-width: 300px;
  margin-top: -2rem;
  margin-left: 2rem;
}

.page-front-right {
  width: 30vw;
}
