
.mainBalance {
  text-align: center;
  color: $mainColor !important;
  font-family: "Roboto";

  font-size: 3em;
  font-weight: 400;

  .balanceCurrency {
    margin-left: 0.4em;
    font-size: 0.5em;
  }

  .small span {
    font-size: 0.8em;
  }
  .separator {
    font-weight: 100 !important;
    font-size: 1em !important;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
  .pendingBalance{
    font-size: 0.3em;
    opacity: 0.8;
  }
}