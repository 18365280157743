.comment-list-box-wrapper {
  .source-logo{
    max-width: 10vw;
    margin-left: auto;
    margin-right: auto;
  }

  .source-logo-mobile{
    max-width: 55%;
    margin-left: auto;
    margin-right: auto;
  }

  .comment-box-wrapper{
    img.rating-image{
      max-width: 40%;
    }

    .text-grey {
      font-size: 0.9rem !important;
      color: grey;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
    }

    .box-shadow{
      box-shadow: 0px 5px 5px -3px rgba(63, 81, 181, .19), 0px 8px 10px 1px rgba(63, 81, 181, .12), 0px 3px 14px 2px rgba(63, 81, 181, .09) !important;
      padding: 2rem 1rem;
      height: 380px;
      position: relative;

      .comment-box-footer {
        position: absolute;
        bottom: 2rem;
        width: 90%;
      }
    }

    p.rate {
      font-size: 0.9rem !important;
      padding-top: 0.5rem;
    }

    .comment-user-name{
      font-size: 0.9rem!important;
    }

    .date{
      font-size: 0.8rem !important;
    }

  }

}
  

  