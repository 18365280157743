.external-payments {

  .requested-amount{
    color: $mainColor;
    font-size: 5vh;
    font-weight: 300;
    font-family: "Roboto";
    .currency{
      font-size: 3vh;
      font-weight: 300;
    }
  }
  .refund-policy{
    background-color: #edce5152;
    font-size: 0.7em;
    line-height: 1.3;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-weight: 400;
  }

  @media only screen and (min-width: 600px) {

  }

}




