.menu-header {
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
}

.menu-header-avatar {
  padding-bottom: 1rem;
  /*padding-top: 0.5rem;*/
}

.menu-header-btn-close {
  padding: 1rem;
  margin-bottom: -2.2rem;
}

.menu-header-name,
h5.menu-header-name {
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  /*max-width: 300px;*/
}