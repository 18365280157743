footer {
  font-size: 14px;
  margin-top: 5em;
  padding-bottom: 5em;
  padding-top: 3em;

  background-color: #041266;

  h3 {
    font-weight: 700 !important;
    color: white !important;
  }

  p {
    color: white !important;
  }

  .logo-link img {
    margin-top: 1.5em;
    max-width: 30%;
  }

  .socials {

    button,a {
      background-color: white;
      margin-right: 10px;
      color: #041266;

      &:hover {
        background-color: rgba(white, 0.4);
      }
    }
  }

  .security {
    height: 84px;
    .vertical-align {
      vertical-align: middle;
    }
    img {
      padding-right: 4em;
    }
  }

  ul.footerMenu {
    list-style: none;
    padding: 0;
    margin: 0;
    li a {
      text-decoration: none;
      color: white;
      &:hover {
        color: rgba(white, 0.4);
      }
    }
  }

  &.external-payment-footer{
    margin-top: 0;
    padding-top: 0;
    background-color: transparent;
    font-family: "Roboto";
    ul.links{
      list-style: none;
      display: inline-flex;
      padding: 0;
      li{
        margin-right: 15px;
      }
    }
    p,li,a{
      color: #93a2ac !important;

    }
    .security {
      height: auto;
      img {
        padding-right: 10px;
        max-width: 7vh;
      }
    }
  }

}
