.payment-resume-terms-privacy,
.MuiTypography-body1.payment-resume-terms-privacy{
    span {
        line-height: 1;
    }
}

.payment-resume-box {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.payment-resume-subtitle{
    padding-left: 1rem;
    padding-right: 1rem;
}