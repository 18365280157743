.label-size-10 {
    font-size: 12px;
}
.label-size-12 {
    font-size: 12px;
}
.label-size-14 {
    font-size: 14px;
}
.label-size-16 {
    font-size: 16px;
}
/*----------------------- size ----*/ 
.label-size-xs {
    font-size: 10px;
}
.label-size-sm {
    font-size: 14px;
}
.label-size-md,
.label-size-md p,
.MuiTypography-root.label-size-md
 {
    font-size: 16px ;
}
.label-size-lg {
    font-size: 21px;
}
.label-size-xl {
    font-size: 48px;
}
/*----------------------- weight ----*/ 
.label-weight-sm {
    font-weight: 200;
}
.label-weight-md {
    font-weight: 400;
}
.label-weight-lg {
    font-weight: 700;
}
/*----------------------------*/