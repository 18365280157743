


.chosePaymentMethod{
  .disableMethod{
    opacity: 0.6;
  }
  .payMethod{
    h6 {
      font-size: 1rem;
    }
  }
}