.public {

  h2 {
    font-size: 32px;
    font-weight: 300;
    color: $mainColor;
    letter-spacing: 2px;
    margin-bottom: .5em !important;
    text-transform: uppercase;
  }

  h3 {
    font-size: 21px !important;
    font-weight: 700 !important;
    margin-bottom: 1em !important;
  }

  p {
    font-size: 18px !important;

    &.subheading {
      font-size: 21px !important;
    }
    &.subheadingList {
      text-transform: uppercase;
      font-size: 13px !important;
      font-weight: 400;
      letter-spacing: 1px;
      color: $mainColor;
    }

  }

  .hero-bg-left {
    position: absolute;
    top: -1px;
    left: 0;
    z-index: -20;
    display: block;
  }

  .hero-bg-blue {
    position: absolute;
    top: -1px;
    right: 0;
    z-index: -10;
    display: block;
    max-width: 70%;
  }

  .providerList {
    display: flex;
    flex-wrap: wrap;

    .item {
      flex: 1 0 25%;
      box-sizing: border-box;
      color: #171e42;
      height: 50%;
      max-height: 80%;
      border-right: 2px solid #b6c8fb;
      &:last-child {
        border: none;
      }

      img {
        max-width: 80%;
        position: inherit !important;
      }

    }

  }

  .inline {
    display: inline-flex;
  }

  .iconImage {
    background-color: $mainColor;
    display: inline-block;
    padding: 20px;
    border-radius: 100%;
    color: white;
  }

  .background-filled {
    background-color: #eaeef580;
    margin-bottom: -70px;
    position: relative;

    .bringFront {
      z-index: 2;
    }
    img {
      position: absolute;

      &.imgBackgroundFilledRight {
        bottom: 0;
        right: 0;
        max-height: 90%;
        z-index: 1;
      }
      &.imgBackgroundFilledLeft {
        bottom: 0;
        left: 0;
        max-height: 60%;
      }
      &.imgBackgroundFilledCircle {
        right: 25%;
        top: -70px;
        height: 130px;
      }
    }
  }

  .background-color-container {
    &.gray {
      background-color: #eaeef580;
    }
  }

  .bottom-section {
    margin-bottom: -70px;
    position: relative;
    img {
      position: absolute;
      &.imgBackgroundFilledRight {
        bottom: 0;
        right: 0;
        max-height: 90%;
        z-index: 1;
      }
      &.imgBackgroundFilledLeft {
        bottom: 0;
        left: 0;
        max-height: 90%;
        z-index: 1;
      }
      &.imgBackgroundFilledLeftM {
        bottom: 0;
        left: 0;
        z-index: 1;
        max-width: 100%;
      }
    }
  }

}
