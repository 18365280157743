.calculator {
  margin: auto;
  width: 100%;
  padding: 0;
  font-family: "Roboto";


  .card-icon-wrapper{
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    .card-icon {
      max-height: 2.5rem;
      max-width: 2.5rem;
    }
  }

  .fix-svg {
    svg {
      margin-right: 5px;
    }
  }

  .alert.alert-info-main{
    font-size: 0.8rem !important;
  }

  .info-expansion-summary > div{
    margin-top: 0;
    margin-bottom: -5px;
  }

  .info-expansion-box{
    padding-left: 0;
    padding-right: 5px;
  }

  .info-expansion-detail{
    font-size: 0.9rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0;
  }

  .info-svg{
    margin-right: -9px;
  }

}


.icon-line-wrapper{
  width: 80%;
  margin: auto;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }

  .icon-line{
    display: flex;

    .icon{
      margin-right: 1em;
      svg {
        overflow: hidden;
        vertical-align: middle;
        background-color: $mainColor;
        border-radius: 50%;
        padding: 6px;
        color: white;
      }

    }
  }
}