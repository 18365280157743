.payment-form-checkbox {
    padding: 0px 8px;
}

.payment-form-checkbox span {
    margin-right: 6px;
}

.page-payment-content div.MuiGrid-root .MuiGrid-item {
    margin-top: 0.5rem;
}