@import "../../app/scss/variables";

.movement-margin-right {
  margin-right: 10px;
}

.movement-list-item {
  margin-left: 10px;
  margin-right: 10px;
}

.movement-item {
  overflow: hidden;
}

.movement-item span {
  font-weight: 700;
  color: $mainColor;
  margin-bottom: 4px;
}

.movement-item-amount,
.MuiListItemText-root.movement-item-amount {
  min-width: 6.5rem;
}

.movement-name {
  overflow: hidden;
}

.movement-name-title {
  font-weight: 700;
}

.movement-name span.movement-name-card {
  font-weight: 700;
}

.movement-item-avatar {
  margin-top: 10px;
}

.movement-item-subtitles span {
  margin-top: 4px;
}