button.sec-btn {
    background-color: rgb(35, 44, 83);
    color: white;
    padding: 8px 22px;
    border-radius: 4px;
}

button.sec-btn:hover {
    background-color: rgb(35, 44, 83);
    color: white;
}