.payment-show-qr-section {
  padding-top: 1rem;
  width: 100%;
  canvas {
    padding-left: 3px;
    margin-top: -7px;
  }
}

.payment-show-box {
  border: 1px;
  border-color: #dddce2;
  border-style: solid;
}

.payment-show-share,
.MuiGrid-root.payment-show-share{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

p.payment-show-date {
  /*padding-top: 10px;*/
}


div.MuiGrid-root.box-label-right {
  padding-right: 1rem;
}

div.payment-show-url.MuiGrid-root.MuiGrid-item{
  margin: 0;
}

.payment-show-options{
  padding: 1rem;
  padding-bottom: 2rem;
}

.payment-show-subtitle{
  padding-left: 1rem;
  padding-right: 1rem;

  text-align: center;
  text-justify: 'inter-palabra';
}
