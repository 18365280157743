.shareIcon {
    -webkit-box-shadow: 3px 3px 10px 0px rgba(74, 89, 217, 0.32);
    box-shadow: 3px 3px 10px 0px rgba(74, 89, 217, 0.32);
    margin-right: 15px;
    border-radius: 50%;
}

.share-btn-lst,
h6.share-btn-lst{
    overflow: visible;
}