.movement-item {
    overflow: hidden;
}

div.MuiGrid-root.MuiGrid-item.movement-content{
    margin: 0;
}

.MuiGrid-root.movement-data-filter {
    margin-bottom: -8px;
}

.page-movement-nodata,
.MuiGrid-root.page-movement-nodata {
    margin-top: 1.3rem;
}

div.page-movement-empty{
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
    h6 {
        width: 100%;
    }
}