.menu-page-content {
  height: 100%;
}

.menu-page-link {
  margin-bottom: 1rem;
  li {
    padding-left: 1rem;
    padding-bottom: 0.5rem;
  }
}

svg.menu-icon-user {
    fill: #39365B;
} 

.menu-page {
  div.MuiDrawer-paper {
    width: 100%;
  }
}
