$lightColor: #6574e2;
$mainColor: #4a59d9;
$darkColor: #4552c9;
$baseTextColor: #39365b;
$baseTextColorDark: #0d0c15;
$baseYellow: #f6d25a;
$baseRed: #fa5061;
$baseGray: #757588;
$baseGrayLight: #f0f3f9;

$color1: #c52887;
$color2: #3d3991;
$color3: #109e90;
$color4: #01579b;
$color5: #06b4c1;
$color6: #efa329;
$color7: #ef5270;
$color8: #5573a4;
$color9: #f77a1c;
$color10: #495b53;
$color11: #7d1f93;
$color12: #f4654a;

/* ----------------------------- */
.bg-color-gray-light, 
.MuiAvatar-root.bg-color-gray-light {
  background-color: $baseGrayLight;
}

.gray-label,
.MuiTypography-root.gray-label {
  color: $baseGray;
}

.text-color-gray,
.MuiTypography-root.text-color-gray {
  color: $baseGray;
}

.text-color-main-blue,
.text-color-main-blue svg {
  color: $mainColor;
}

.text-color-warning {
  color: $baseYellow;
}

.text-color-error {
  color: $baseRed;
}

.text-color-1 {
  color: $color1 !important;
}

.text-color-2 {
  color: $color2 !important;
}

.text-color-3 {
  color: $color3 !important;
}

.text-color-4 {
  color: $color4 !important;
}

.text-color-5 {
  color: $color5 !important;
}

.text-color-6 {
  color: $color6 !important;
}

.text-color-7 {
  color: $color7 !important;
}

.text-color-8 {
  color: $color8 !important;
}

.text-color-9 {
  color: $color9 !important;
}

.text-color-10 {
  color: $color10 !important;
}

.text-color-11 {
  color: $color11 !important;
}

.text-color-12 {
  color: $color12 !important;
}
