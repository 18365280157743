body {
  margin: 0;
  padding: 0;
  
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', Poppins;
}

button.MuiButtonBase-root {
  padding: 0;
}

@import "./scss/label";
@import "./scss/variables";
@import "./scss/helpers";
@import "./scss/commons";
@import "./scss/bootomNav";
@import "./scss/balanceComponent";
@import "./scss/appMenuBar";
@import "./scss/publicHeaderMenu";
@import "./scss/landing";
@import "./scss/use_case_remittance";
@import "./scss/voucher";
@import "./scss/footer";
@import "./scss/legals";
@import "./scss/carousel";
@import "./scss/appSnackbar";
@import "./scss/faqs";
@import "./scss/publicExternalPayment";
@import "./scss/genericlanding";
@import "./scss/calculator";
@import "./scss/bulletListItem";
@import "./scss/productBox";
@import "./scss/commentListBox";
@import "./scss/remittance";
@import "./scss/join_telegram";
@import "./scss/addFundView";
@import "./scss/cmpHome";
@import "./scss/boxLayout";
@import "./scss/appMenu";
@import "./scss/color";