$lightColor: #6574e2;
$mainColor: #4a59d9;
$darkColor: #4552c9;

$baseTextColor: #39365b;

$baseTextColorDark: #0d0c15;

$baseYellow: #f6d25a;

$baseRed: #fa5061;
