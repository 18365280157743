.menu-item {
  padding: 0 !important;
  margin: 0;
}

.menu-item div {
  padding: 0.3rem 0.5rem;
}

.menu-item div div {
  min-width: 20px;
}

.menu-item .MuiListItem-gutters {
  padding: 0;
}
